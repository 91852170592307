/**
 * Services are generated and returned via a getter function
 * to avoid a complex DI system, until it is necessary
 */
import config from '@/config';
import Vue from 'vue';
import {
  AccountService,
  AiChatbotService,
  AuthenticationService,
  AuthorizationService,
  BankingReferenceService,
  BeneficiaryService,
  BrandingService,
  ClientService,
  CommService,
  ComplianceAdminService,
  CreditService,
  CouttsService,
  CustomerReferenceService,
  FeesAndChargesService,
  IndividualService,
  LimitsService,
  PartnerAdminService,
  RiskReportsService,
  TradeService,
  UserService,
  WalletService,
  PricingEngineService,
  NotificationService,
  ReconciliationService,
  SpreadsService,
  RateService,
  PartnerCommissionSettingsService,
  FinancialReportsService,
  TradeReportsService,
  VoiceDeskService,
  SecurityReportsService,
  UpdatesService,
  DocumentsService,
  FxReferenceService,
  PaymentsService,
  TermsheetService,
  FeatureFlagService,
  ClientCollateralService,
  LiquidityProviderService,
  RiskDecisionService,
  ReconcilableTransactionsService,
  WebhooksService,
  IntegrationsService,
  PremiumPaymentService,
  LimitOrderService,
} from 'ah-api-gateways';
import {
  HttpNotifier,
  HttpService,
  SocketService,
  ConsoleLogService,
  InternalCustomAxiosRequestConfig,
} from 'ah-requests';
import { generateUUID } from 'ah-common-lib/src/helpers/uuid';
import { ToastType } from 'ah-common-lib/src/toast/toastInterfaces';

export interface Services {
  account: AccountService;
  aiChatbot: AiChatbotService;
  auth: AuthenticationService;
  authz: AuthorizationService;
  bankingReference: BankingReferenceService;
  beneficiary: BeneficiaryService;
  branding: BrandingService;
  brandingPublic: BrandingService;
  client: ClientService;
  comm: CommService;
  compliance: ComplianceAdminService;
  creditLimits: CreditService;
  customerReference: CustomerReferenceService;
  fees: FeesAndChargesService;
  http: HttpService;
  individual: IndividualService;
  limits: LimitsService;
  partner: PartnerAdminService;
  risk: RiskReportsService;
  riskDecision: RiskDecisionService;
  reconcilableTransactions: ReconcilableTransactionsService;
  socket: SocketService;
  trade: TradeService;
  user: UserService;
  wallet: WalletService;
  pricingEngine: PricingEngineService;
  notification: NotificationService;
  reconciliation: ReconciliationService;
  spreads: SpreadsService;
  partnerCommissions: PartnerCommissionSettingsService;
  financialReports: FinancialReportsService;
  tradeReports: TradeReportsService;
  voiceDesk: VoiceDeskService;
  securityReports: SecurityReportsService;
  updates: UpdatesService;
  documents: DocumentsService;
  premiumService: PremiumPaymentService;

  customerReferenceOBO: CustomerReferenceService;
  clientOBO: ClientService;
  pricingEngineOBO: PricingEngineService;
  tradeOBO: TradeService;
  walletOBO: WalletService;
  riskOBO: RiskReportsService;
  limitsOBO: LimitsService;
  feesOBO: FeesAndChargesService;
  beneficiaryOBO: BeneficiaryService;
  spreadsOBO: SpreadsService;
  individualOBO: IndividualService;
  bankingReferenceOBO: BankingReferenceService;
  notificationOBO: NotificationService;
  paymentsOBO: PaymentsService;
  rates: RateService;
  fxReference: FxReferenceService;
  payments: PaymentsService;
  termsheetService: TermsheetService;
  couttsService: CouttsService;
  featureFlag: FeatureFlagService;
  collateralProfile: ClientCollateralService;
  liquidityProvider: LiquidityProviderService;
  webhooks: WebhooksService;
  integrations: IntegrationsService;
  limitOrder: LimitOrderService;
}

const services: Services = {} as any;

export function getServices(generate = false): Services {
  if (generate) {
    const log = new ConsoleLogService(config.isProduction);

    const notifications: HttpNotifier = {
      error(message) {
        Vue.toast.error(message.message!, {
          ...message,
          toastType: (message.toastType as ToastType) || ToastType.danger,
        });
      },
    };
    const http = new HttpService(log, notifications);
    const socket = new SocketService();

    http.interceptRequest((httpConfig: InternalCustomAxiosRequestConfig<any>) => {
      httpConfig.headers['x-ah-service'] = 'ah-admin-panel';
      httpConfig.headers['x-ah-trace-id'] = generateUUID();
      return httpConfig;
    });

    const builtServices: Services = {
      http,
      socket,

      // AI chatbot
      aiChatbot: new AiChatbotService(http, config.customerAPIGatewayAdminUrl),

      // Customer APIs
      account: new AccountService(http, config.customerAPIGatewayUrl),
      auth: new AuthenticationService(http, config.customerAPIGatewayUrl),
      authz: new AuthorizationService(http, config.customerAPIGatewayUrl, config.customerAPIGatewayAdminUrl),
      branding: new BrandingService(http, config.customerAPIGatewayAdminUrl),
      featureFlag: new FeatureFlagService(http, config.customerAPIGatewayUrl),
      integrations: new IntegrationsService(http, config.customerAPIGatewayUrl),

      // Public branding service, for maintenance checks
      brandingPublic: new BrandingService(http, config.customerAPIGatewayUrl),

      // Customer Admin APIs:
      individual: new IndividualService(http, config.customerAPIGatewayAdminUrl),
      customerReference: new CustomerReferenceService(http, config.customerAPIGatewayAdminUrl),
      client: new ClientService(http, config.customerAPIGatewayAdminUrl),
      comm: new CommService(http, socket, config.customerAPIGatewayAdminUrl),
      compliance: new ComplianceAdminService(http, config.customerAPIGatewayAdminUrl),
      partner: new PartnerAdminService(http, config.customerAPIGatewayAdminUrl),
      user: new UserService(http, config.customerAPIGatewayAdminUrl),
      updates: new UpdatesService(http, config.customerAPIGatewayAdminUrl),
      notification: new NotificationService(http, socket, config.customerAPIGatewayAdminUrl),
      webhooks: new WebhooksService(http, config.customerAPIGatewayAdminUrl),

      // Banking Admin APIs
      bankingReference: new BankingReferenceService(http, config.bankingAPIGatewayAdminUrl),
      beneficiary: new BeneficiaryService(http, config.bankingAPIGatewayAdminUrl),
      fees: new FeesAndChargesService(http, config.bankingAPIGatewayAdminUrl, config.analyticsAdminUrl),
      wallet: new WalletService(http, config.bankingAPIGatewayAdminUrl, config.bankingAPIGatewayUrl),
      payments: new PaymentsService(http, config.bankingAPIGatewayAdminUrl),
      couttsService: new CouttsService(http, config.bankingAPIGatewayAdminUrl),
      riskDecision: new RiskDecisionService(http, config.bankingAPIGatewayAdminUrl),
      reconcilableTransactions: new ReconcilableTransactionsService(http, config.bankingAPIGatewayAdminUrl),

      // FX Admin APIs
      creditLimits: new CreditService(http, config.bankingAPIGatewayAdminUrl),
      limits: new LimitsService(http, config.fxAPIGatewayAdminUrl),
      trade: new TradeService(http, config.fxAPIGatewayAdminUrl, config.fxAPIGatewayAdminUrl),
      pricingEngine: new PricingEngineService(http, config.fxAPIGatewayUrl, config.fxAPIGatewayAdminUrl),
      spreads: new SpreadsService(http, config.fxAPIGatewayAdminUrl),
      rates: new RateService(http, config.fxAPIGatewayUrl),
      voiceDesk: new VoiceDeskService(http, config.fxAPIGatewayAdminUrl),
      fxReference: new FxReferenceService(http, config.fxAPIGatewayUrl),
      termsheetService: new TermsheetService(http, config.fxAPIGatewayAdminUrl),
      collateralProfile: new ClientCollateralService(http, config.fxAPIGatewayUrl, config.fxAPIGatewayAdminUrl),
      liquidityProvider: new LiquidityProviderService(http, config.fxAPIGatewayUrl, config.fxAPIGatewayAdminUrl),
      premiumService: new PremiumPaymentService(http, config.fxAPIGatewayAdminUrl),
      limitOrder: new LimitOrderService(http, config.fxAPIGatewayAdminUrl),

      // Analytics Admin APIs:
      risk: new RiskReportsService(http, config.analyticsAdminUrl),
      financialReports: new FinancialReportsService(http, config.analyticsAdminUrl),
      tradeReports: new TradeReportsService(http, config.analyticsAdminUrl),
      securityReports: new SecurityReportsService(http, config.analyticsAdminUrl),

      // Settlements Admin APIs:
      reconciliation: new ReconciliationService(http, config.settlementsAdminUrl),
      partnerCommissions: new PartnerCommissionSettingsService(http, config.settlementsAdminUrl),

      documents: new DocumentsService(
        http,
        config.customerAPIGatewayAdminUrl,
        config.analyticsAdminUrl,
        config.fxAPIGatewayAdminUrl,
        config.bankingAPIGatewayAdminUrl
      ),

      // OBO APIs
      clientOBO: new ClientService(http, config.customerAPIGatewayUrl),
      pricingEngineOBO: new PricingEngineService(http, config.fxAPIGatewayUrl, config.fxAPIGatewayAdminUrl),
      tradeOBO: new TradeService(http, config.fxAPIGatewayUrl, config.fxAPIGatewayAdminUrl),
      walletOBO: new WalletService(http, config.bankingAPIGatewayUrl, config.bankingAPIGatewayUrl),
      riskOBO: new RiskReportsService(http, config.analyticsUrl),
      limitsOBO: new LimitsService(http, config.fxAPIGatewayUrl),
      feesOBO: new FeesAndChargesService(http, config.bankingAPIGatewayUrl, config.analyticsUrl),
      beneficiaryOBO: new BeneficiaryService(http, config.bankingAPIGatewayUrl),
      spreadsOBO: new SpreadsService(http, config.fxAPIGatewayUrl),
      individualOBO: new IndividualService(http, config.customerAPIGatewayUrl),
      bankingReferenceOBO: new BankingReferenceService(http, config.bankingAPIGatewayUrl),
      notificationOBO: new NotificationService(http, socket, config.customerAPIGatewayUrl),
      paymentsOBO: new PaymentsService(http, config.bankingAPIGatewayUrl),

      // FIXME - not used in OBO context, but due to a bug in the API we need to use the public gateway on address fields
      customerReferenceOBO: new CustomerReferenceService(http, config.customerAPIGatewayUrl),
    };

    Object.assign(services, builtServices);
  }
  return services;
}
