<script setup lang="ts">
import { computed } from 'vue';
import VersionAlert from 'ah-common-lib/src/common/components/VersionAlert.vue';
import config from './config';
import ChatBotOverlay from '@/app/chatbot/ChatBotOverlay.vue';
import { useRoute } from 'vue-router/composables';

const showDevTools = computed(() => !!config.showDevTools);

const currRoute = useRoute();
</script>

<template>
  <div id="app-holder">
    <RouterView />
    <VersionAlert v-if="showDevTools" />
    <ChatBotOverlay :client-id="currRoute.params.clientId" :partner-id="currRoute.params.partnerId" />
  </div>
</template>
