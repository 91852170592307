import Vue, { ComponentOptions } from 'vue';
import { getServices } from '@/services';
import { AHAdminRouteConfig, AHAdminRoute, AHAdminRouteRecord } from './ahAdminRoute';
import { capitalize } from 'lodash';
import { AuthorityType, ComplianceStatus, getCaseName, SYSTEM_ID } from 'ah-api-gateways';
import ConfirmEmailChangeView from '@/app/views/dashboard/settings/ConfirmEmailChangeView.vue';
import { storeSetupDone } from '../store';
import { useAuthStore } from '../store/authStore';
import { getFirstAvailableLink } from '@/app/constants/sideNavLinks';
import { PaymentReconType } from '../constants/reconciliacion';
import config from '@/config';

const blankComponent = (name = 'BlankRouter'): ComponentOptions<Vue> => ({
  name,
  render(c) {
    return c('router-view');
  },
});

const PAGE_TITLE = 'X-Office';

function tabbedChildRoute(path: string, child: AHAdminRouteConfig, query?: any, title?: string) {
  title = title || capitalize(path);
  query = query || { tab: path };
  return {
    path,
    component: blankComponent(title),
    redirect: () => ({ path: '.', append: true, query }),
    meta: {
      breadcrumbTitle: title,
      breadcrumbPath: tabChildBreadcrumbPath(query),
    },
    children: [child],
  };
}

const tabChildBreadcrumbPath =
  (tabNames: { [key: string]: string } | string) => (route: AHAdminRoute, record: AHAdminRouteRecord) => {
    if (!record.parent) {
      return '';
    }

    const tabNamesObject = typeof tabNames === 'string' ? { tab: tabNames } : tabNames;

    let out = record.parent.path;
    Object.keys(tabNamesObject).forEach((k, index) => {
      out = `${out}${index > 0 ? '&' : '?'}${encodeURIComponent(k)}=${encodeURIComponent(tabNamesObject[k])}`;
    });
    return out;
  };

const sessionRoutes: AHAdminRouteConfig[] = [
  {
    path: 'login',
    component: () => import(/* webpackChunkName: "publicViews" */ '../views/public/session/LoginView.vue'),
    props: (route) => {
      let query = {};
      if (typeof route.query.q === 'string') {
        try {
          query = JSON.parse(route.query.q);
        } catch (e) {}
      }
      return { redirectTo: route.query.r, redirectToQuery: query };
    },
    meta: { unAuthOnly: true },
  },
  {
    path: 'login/azure',
    component: () => import(/* webpackChunkName: "publicViews" */ '../views/public/session/LoginWithAzureView.vue'),
    props: (route) => ({ redirectTo: route.query.r, redirectToQuery: route.query.q }),
    meta: { unAuthOnly: true },
  },
  {
    path: 'resetPassword',
    component: () => import(/* webpackChunkName: "publicViews" */ '../views/public/session/ForgottenPasswordView.vue'),
    meta: { unAuthOnly: true },
  },
  {
    path: 'resetPassword/:token',
    props: true,
    component: () => import(/* webpackChunkName: "publicViews" */ '../views/public/session/ResetPasswordView.vue'),
    meta: { unAuthOnly: true },
  },
];

const errorRoutes: AHAdminRouteConfig[] = [
  {
    path: '/500',
    component: () => import(/* webpackChunkName: "publicViews" */ '../views/public/error/500View.vue'),
  },
];

function dashboardPartnerRoute(): AHAdminRouteConfig {
  return {
    path: ':partnerId',
    props: true,
    component: blankComponent('SinglePartner'),
    meta: {
      requireAnyAuthorities: [AuthorityType.VIEW_ALL_PARTNERS, AuthorityType.VIEW_TARGET_PARTNER],
      breadcrumbTitle: (route: AHAdminRoute) => {
        const services = getServices();
        if ((route.params as any).partnerId) {
          return services.partner
            .getPartner((route.params as any).partnerId)
            .toPromise()
            .then(
              (agent) => `${agent.name}`,
              () => 'Not Found!'
            );
        }
        return 'No ID';
      },
    },
    children: [
      {
        path: '',
        component: () =>
          import(/* webpackChunkName: "privateViews" */ '../views/dashboard/partners/SinglePartnerView.vue'),
        props: true,
        meta: { ignoreBreadcrumb: true },
      },
      tabbedChildRoute('clients', dashboardClientRoute(), { tab: 'people', peopleTab: 'clients' }),
      tabbedChildRoute('agents', dashboardAgentRoute(), { tab: 'people', peopleTab: 'agents' }),
      tabbedChildRoute('beneficiaries', dashboardBeneficiaryRoute(), { tab: 'people', peopleTab: 'beneficiaries' }),
      tabbedChildRoute('trades', dashboardTradeRoute(), { tab: 'trading', tradingTab: 'trades' }),
      { path: '*', redirect: '' },
    ],
  };
}

function dashboardClientRoute(pathPrefix = ''): AHAdminRouteConfig {
  return {
    path: `${pathPrefix ? pathPrefix + '/' : ''}:clientId`,
    props: true,
    component: blankComponent('SingleClient'),
    meta: {
      title: `Client | ${PAGE_TITLE}`,
      requireAnyAuthorities: [AuthorityType.VIEW_CLIENTS],
      breadcrumbTitle: (route: AHAdminRoute) => {
        const services = getServices();
        if ((route.params as any).clientId) {
          return services.client
            .getClient((route.params as any).clientId)
            .toPromise()
            .then(
              (client) => client.name,
              () => 'Not Found!'
            );
        }
        return 'No ID';
      },
    },
    children: [
      {
        path: '',
        component: () =>
          import(/* webpackChunkName: "privateViews" */ '../views/dashboard/clients/SingleClientView.vue'),
        props: true,
        meta: {
          ignoreBreadcrumb: true,
        },
      },
      tabbedChildRoute('individuals', dashboardIndividualRoute(), { tab: 'people', peopleTab: 'individuals' }),
      tabbedChildRoute('beneficiaries', dashboardBeneficiaryRoute(), {
        tab: 'people',
        peopleTab: 'beneficiaries',
      }),
      tabbedChildRoute('trades', dashboardTradeRoute(), { tab: 'trading', tradingTab: 'trades' }),
    ],
  };
}
// TODO - DO: Position limits screen will be hidden at the moment in favour of the new Credit and Limits screen, this will be completely removed at a later stage.
// function dashboardPositionLimitsRoute(pathPrefix = ''): AHAdminRouteConfig {
//   return {
//     path: `${pathPrefix ? pathPrefix + '/' : ''}:clientId`,
//     props: true,
//     component: blankComponent('SingleClientMargin'),
//     meta: {
//       title: `Margin Credit Facility | ${PAGE_TITLE}`,
//       requireAnyAuthorities: [AuthorityType.VIEW_ALL_RISK_REPORTS],
//       breadcrumbTitle: (route: AHAdminRoute) => {
//         const services = getServices();
//         if ((route.params as any).clientId) {
//           return services.client
//             .getClient((route.params as any).clientId)
//             .toPromise()
//             .then(
//               (client) => client.name,
//               () => 'Not Found!'
//             );
//         }
//         return 'No ID';
//       },
//     },
//     children: [
//       {
//         path: '',
//         component: () =>
//           import(
//             /* webpackChunkName: "privateViews" */ '../views/dashboard/creditControl/SingleClientPositionLimitsView.vue'
//           ),
//         props: true,
//         meta: {
//           ignoreBreadcrumb: true,
//           requireAnyAuthorities: [AuthorityType.VIEW_TARGET_LIMITS_PROFILE, AuthorityType.VIEW_CLIENT_USAGE_REPORTS],
//         },
//       },
//     ],
//   };
// }

// TODO - DO: MCF screen will be hidden at the moment in favour of the new Credit and Limits screen, this will be completely removed at a later stage.

// function dashboardClientMarginFacilityRoute(pathPrefix = ''): AHAdminRouteConfig {
//   return {
//     path: `${pathPrefix ? pathPrefix + '/' : ''}:clientId`,
//     props: true,
//     component: blankComponent('SingleClientMargin'),
//     meta: {
//       title: `Margin Credit Facility | ${PAGE_TITLE}`,
//       requireAnyAuthorities: [AuthorityType.VIEW_ALL_RISK_REPORTS],
//       breadcrumbTitle: (route: AHAdminRoute) => {
//         const services = getServices();
//         if ((route.params as any).clientId) {
//           return services.client
//             .getClient((route.params as any).clientId)
//             .toPromise()
//             .then(
//               (client) => client.name,
//               () => 'Not Found!'
//             );
//         }
//         return 'No ID';
//       },
//     },
//     children: [
//       {
//         path: '',
//         meta: {
//           ignoreBreadcrumb: true,
//         },
//         component: () =>
//           import(
//             /* webpackChunkName: "privateViews" */ '../views/dashboard/creditControl/SingleClientMarginCreditView.vue'
//           ),
//         props: true,
//       },
//     ],
//   };
// }

function dashboardClientCreditAndLimitsRoute(pathPrefix = ''): AHAdminRouteConfig {
  return {
    path: `${pathPrefix ? pathPrefix + '/' : ''}:clientId`,
    props: true,
    component: blankComponent('SingleCreditAndLimits'),
    meta: {
      title: `Credit and Limits | ${PAGE_TITLE}`,
      requireAnyAuthorities: [AuthorityType.VIEW_ALL_RISK_REPORTS],
      breadcrumbTitle: (route: AHAdminRoute) => {
        const services = getServices();
        if ((route.params as any).clientId) {
          return services.client
            .getClient((route.params as any).clientId)
            .toPromise()
            .then(
              (client) => client.name,
              () => 'Not Found!'
            );
        }
        return 'No ID';
      },
    },
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "privateViews" */ '../views/dashboard/creditControl/SingleClientCreditAndLimitsView.vue'
          ),
        props: true,
        meta: {
          ignoreBreadcrumb: true,
        },
      },
    ],
  };
}

function dashboardIndividualRoute(pathPrefix = ''): AHAdminRouteConfig {
  return {
    path: `${pathPrefix ? pathPrefix + '/' : ''}:individualId`,
    props: true,
    component: blankComponent('SingleIndividual'),
    meta: {
      title: `Individual | ${PAGE_TITLE}`,
      requireAllAuthorities: [
        AuthorityType.VIEW_MEMBERS,
        AuthorityType.VIEW_MEMBER_REPORTS,
        AuthorityType.VIEW_USER_ACCOUNTS,
      ],
      breadcrumbTitle: (route: AHAdminRoute) => {
        const services = getServices();
        if ((route.params as any).individualId) {
          return services.individual
            .getIndividual((route.params as any).individualId)
            .toPromise()
            .then(
              (individual) => `${individual.firstName} ${individual.lastName}`,
              () => 'Not Found!'
            );
        }
        return 'No ID';
      },
    },
    children: [
      {
        path: '',
        component: () =>
          import(/* webpackChunkName: "privateViews" */ '../views/dashboard/individuals/SingleIndividualView.vue'),
        props: true,
        meta: {
          ignoreBreadcrumb: true,
        },
      },
    ],
  };
}

function dashboardBeneficiaryRoute(pathPrefix = ''): AHAdminRouteConfig {
  return {
    path: `${pathPrefix ? pathPrefix + '/' : ''}:beneficiaryId`,
    props: true,
    component: blankComponent('SingleBeneficiary'),
    meta: {
      title: `Beneficiary | ${PAGE_TITLE}`,
      requireAnyAuthorities: [AuthorityType.VIEW_BENEFICIARIES],
      breadcrumbTitle: (route: AHAdminRoute) => {
        const services = getServices();
        if ((route.params as any).beneficiaryId) {
          return services.beneficiary
            .getBeneficiary((route.params as any).beneficiaryId)
            .toPromise()
            .then(
              (beneficiary) =>
                beneficiary.firstName ? `${beneficiary.firstName} ${beneficiary.lastName}` : beneficiary.name!,
              () => 'Not Found!'
            );
        }
        return 'No ID';
      },
    },
    children: [
      {
        path: '',
        component: () =>
          import(/* webpackChunkName: "privateViews" */ '../views/dashboard/beneficiaries/SingleBeneficiaryView.vue'),
        props: true,
        meta: {
          ignoreBreadcrumb: true,
        },
      },
    ],
  };
}

function dashboardAgentRoute(pathPrefix = ''): AHAdminRouteConfig {
  return {
    path: `${pathPrefix ? pathPrefix + '/' : ''}:agentId`,
    props: true,
    component: blankComponent('SingleAgent'),
    meta: {
      title: `Agent | ${PAGE_TITLE}`,
      requireAllAuthorities: [
        AuthorityType.VIEW_MEMBERS,
        AuthorityType.VIEW_MEMBER_REPORTS,
        AuthorityType.VIEW_USER_ACCOUNTS,
      ],
      breadcrumbTitle: (route: AHAdminRoute) => {
        const services = getServices();
        if ((route.params as any).agentId) {
          return services.individual
            .getIndividual((route.params as any).agentId)
            .toPromise()
            .then(
              (agent) => `${agent.firstName} ${agent.lastName}`,
              () => 'Not Found!'
            );
        }
        return 'No ID';
      },
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "privateViews" */ '../views/dashboard/agents/SingleAgentView.vue'),
        props: true,
        meta: {
          ignoreBreadcrumb: true,
        },
      },
    ],
  };
}

function dashboardTradeRoute(pathPrefix = ''): AHAdminRouteConfig {
  return {
    path: `${pathPrefix ? pathPrefix + '/' : ''}:tradeId`,
    props: true,
    component: blankComponent('SingleTrade'),
    meta: {
      title: `Trade | ${PAGE_TITLE}`,
      requireAnyAuthorities: [AuthorityType.VIEW_TRADE_REPORTS],
      breadcrumbTitle: (route: AHAdminRoute) => {
        const services = getServices();
        if ((route.params as any).tradeId) {
          return services.tradeReports
            .listTrades({ id: [(route.params as any).tradeId] })
            .toPromise()
            .then(
              (response) => {
                if (response.list[0]?.referenceNumber) {
                  return `Trade #${response.list[0].referenceNumber}`;
                }
                return 'Not Found!';
              },
              () => 'Not Found!'
            );
        }
        return 'No ID';
      },
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "privateViews" */ '../views/dashboard/trades/TradesListView.vue'),
        props: true,
        meta: {
          ignoreBreadcrumb: true,
        },
      },
    ],
  };
}

function dashboardComplianceCaseRoute(): AHAdminRouteConfig {
  return {
    path: `:clientId`,
    props: (route) => ({ ...route.params }),
    component: () =>
      import(/* webpackChunkName: "privateViews" */ '../views/dashboard/compliance/ComplianceCaseDetailsView.vue'),
    meta: {
      title: `Compliance case | ${PAGE_TITLE}`,
      requireAnyAuthorities: [AuthorityType.VIEW_COMPLIANCE_CASES],
      breadcrumbTitle: (route: AHAdminRoute) => {
        const services = getServices();
        if ((route.params as any).clientId) {
          return services.compliance
            .getClientComplianceCase((route.params as any).clientId)
            .toPromise()
            .then(
              (complianceCase) => `Case for ${getCaseName(complianceCase)}`,
              () => 'Not Found!'
            );
        }
        return 'No ID';
      },
    },
  };
}

function dashboardComplianceCaseListRoutes(
  caseFamily: string,
  bulkStateChanges: { path: string; stateTo: ComplianceStatus }[]
) {
  return [
    {
      path: '',
      component: () =>
        import(/* webpackChunkName: "privateViews" */ '../views/dashboard/compliance/ComplianceCasesListView.vue'),
      props: {
        caseFamily,
      },
      meta: {
        ignoreBreadcrumb: true,
      },
    },
    ...bulkStateChanges.map((bulkAction) => ({
      path: `bulk-actions/${bulkAction.path}`,
      component: () =>
        import(
          /* webpackChunkName: "privateViews" */ '../views/dashboard/compliance/ComplianceCasesBulkActionListView.vue'
        ),
      props: {
        complianceStateTo: bulkAction.stateTo,
      },
      meta: {
        requireAnyAuthorities: [AuthorityType.UPDATE_COMPLIANCE_CASE],
        ignoreBreadcrumb: true,
      },
    })),
  ];
}

const complianceRoutes: AHAdminRouteConfig = {
  path: 'compliance',
  component: blankComponent('Compliance'),
  meta: {
    auth: true,
    ignoreBreadcrumb: true,
  },
  children: [
    {
      path: 'countries',
      component: () => import(/* webpackChunkName: "privateViews" */ '../views/dashboard/compliance/CountriesView.vue'),
      meta: {
        requireAnyAuthorities: [AuthorityType.EDIT_SANCTIONED_COUNTRIES],
        breadcrumbTitle: 'Santioned Countries',
        title: `Santioned Countries | ${PAGE_TITLE}`,
      },
    },
    {
      path: 'open-cases',
      component: blankComponent('ComplianceOpenCases'),
      meta: {
        requireAnyAuthorities: [AuthorityType.VIEW_COMPLIANCE_CASES],
        title: `Compliance - Open Cases | ${PAGE_TITLE}`,
        breadcrumbTitle: 'Compliance - Open Cases',
      },
      children: [
        ...dashboardComplianceCaseListRoutes('open', []),
        dashboardComplianceCaseRoute(),
        { path: '*', redirect: '.' },
      ],
    },
    {
      path: 'approved-cases',
      component: blankComponent('ComplianceApprovedCases'),
      meta: {
        requireAnyAuthorities: [AuthorityType.VIEW_COMPLIANCE_CASES],
        title: `Compliance - Approved Cases | ${PAGE_TITLE}`,
        breadcrumbTitle: 'Compliance - Approved Cases',
      },
      children: [
        ...dashboardComplianceCaseListRoutes('approved', [
          {
            path: 'suspend',
            stateTo: ComplianceStatus.SUSPENDED,
          },
          {
            path: 'deactivate',
            stateTo: ComplianceStatus.DEACTIVATED,
          },
          {
            path: 'resend-terms-and-conditions',
            stateTo: ComplianceStatus.UPDATED_TERMS_AND_CONDITIONS,
          },
        ]),
        dashboardComplianceCaseRoute(),
        { path: '*', redirect: '.' },
      ],
    },
    {
      path: 'rejected-cases',
      component: blankComponent('ComplianceClosedCases'),
      meta: {
        requireAnyAuthorities: [AuthorityType.VIEW_ALL_COMPLIANCE_CASES_REPORTS, AuthorityType.VIEW_COMPLIANCE_CASES],
        title: `Compliance - Rejected Cases | ${PAGE_TITLE}`,
        breadcrumbTitle: 'Compliance - Rejected Cases',
      },
      children: [
        ...dashboardComplianceCaseListRoutes('rejected', []),
        dashboardComplianceCaseRoute(),
        { path: '*', redirect: '.' },
      ],
    },
    {
      path: 'cases',
      component: blankComponent('ComplianceAllCases'),
      meta: {
        requireAnyAuthorities: [AuthorityType.VIEW_ALL_COMPLIANCE_CASES_REPORTS, AuthorityType.VIEW_COMPLIANCE_CASES],
        title: `Compliance - All Cases | ${PAGE_TITLE}`,
        breadcrumbTitle: 'Compliance - All Cases',
      },
      children: [dashboardComplianceCaseRoute(), { path: '', redirect: '../open-cases' }],
    },
    { path: '*', redirect: 'open-cases' },
  ],
};

const reconciliationRoutes: AHAdminRouteConfig = {
  path: 'reconciliation',
  component: blankComponent('Reconciliation'),
  meta: {
    auth: true,
    ignoreBreadcrumb: true,
    requireAnyAuthorities: [AuthorityType.VIEW_RECONCILIATIONS_REPORTS],
  },
  children: [
    {
      path: 'house-account',
      component: () =>
        import(/* webpackChunkName: "privateViews" */ '../views/dashboard/reconciliation/WalletsView.vue'),
      props: {
        walletReconType: 'HOUSE_ACCOUNT',
      },
      meta: {
        ignoreBreadcrumb: true,
        title: `House Account | ${PAGE_TITLE}`,
      },
    },
    {
      path: 'partner-wallets',
      component: () =>
        import(/* webpackChunkName: "privateViews" */ '../views/dashboard/reconciliation/WalletsView.vue'),
      props: {
        walletReconType: 'PARTNER',
      },
      meta: {
        ignoreBreadcrumb: true,
        title: `Partner Wallets | ${PAGE_TITLE}`,
      },
    },
    {
      path: 'client-wallets',
      component: () =>
        import(/* webpackChunkName: "privateViews" */ '../views/dashboard/reconciliation/WalletsView.vue'),
      props: {
        walletReconType: 'CLIENT',
      },
      meta: {
        ignoreBreadcrumb: true,
        title: `Client Wallets | ${PAGE_TITLE}`,
      },
    },
    {
      path: 'funding',
      component: () =>
        import(/* webpackChunkName: "privateViews" */ '../views/dashboard/reconciliation/PaymentsView.vue'),
      props: {
        paymentReconType: PaymentReconType.FUNDING,
      },
      meta: {
        ignoreBreadcrumb: true,
        title: `Funding | ${PAGE_TITLE}`,
      },
    },
    {
      path: 'payments',
      component: () =>
        import(/* webpackChunkName: "privateViews" */ '../views/dashboard/reconciliation/PaymentsView.vue'),
      props: {
        paymentReconType: PaymentReconType.PAYMENT,
      },
      meta: {
        ignoreBreadcrumb: true,
        title: `Payment | ${PAGE_TITLE}`,
      },
    },
    {
      path: 'transfers',
      component: () =>
        import(/* webpackChunkName: "privateViews" */ '../views/dashboard/reconciliation/TransfersView.vue'),
      meta: {
        ignoreBreadcrumb: true,
        title: `Transfers | ${PAGE_TITLE}`,
      },
    },
    { path: '*', redirect: 'house-account' },
  ],
};

const voiceTradesRoutes: AHAdminRouteConfig = {
  path: 'voice-trading-desk',
  component: blankComponent('TradingDesk'),
  meta: {
    auth: true,
    ignoreBreadcrumb: true,
  },
  children: [
    {
      path: 'trades',
      component: blankComponent('VoiceTrades'),
      meta: {
        title: `Trades Viewer | ${PAGE_TITLE}`,
        ignoreBreadcrumb: true,
      },
      children: [
        {
          path: '',
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/VoiceTradeListView.vue'),
          meta: {
            title: `Trades Viewer | ${PAGE_TITLE}`,
            requireAnyAuthorities: [AuthorityType.VIEW_VOICE_TRADES],
          },
        },
        {
          path: 'trade',
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/VoiceTradeEditorView.vue'),
          props: true,
          meta: {
            title: `Create Voice Trade | ${PAGE_TITLE}`,
            requireAnyAuthorities: [AuthorityType.MANAGE_VOICE_TRADES],
          },
        },
        {
          path: 'trade/:tradeId',
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/VoiceTradeEditorView.vue'),
          props: true,
          meta: {
            requireAnyAuthorities: [AuthorityType.MANAGE_VOICE_TRADES],
            title: `Voice Trade | ${PAGE_TITLE}`,
          },
        },
        {
          path: ':tradeId',
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/SingleVoiceTradeView.vue'),
          props: true,
          meta: {
            title: `Voice Trade | ${PAGE_TITLE}`,
            requireAnyAuthorities: [AuthorityType.VIEW_VOICE_TRADES],
          },
        },
      ],
    },
    {
      path: 'confirmation-expiry',
      component: blankComponent('CreateConfirmations'),
      meta: {
        breadcrumbTitle: 'Create Confirmations',
        title: `Create Confirmations | ${PAGE_TITLE}`,
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
            title: `Create Confirmations | ${PAGE_TITLE}`,
            requireAnyAuthorities: [AuthorityType.MANAGE_VOICE_TRADES],
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/ExpiryConfirmationView.vue'),
        },
      ],
    },
    {
      path: 'house-cash',
      component: blankComponent('HouseCash'),
      meta: {
        breadcrumbTitle: 'House Cash',
        title: `House Cash | ${PAGE_TITLE}`,
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
            title: `House Cash | ${PAGE_TITLE}`,
            requireAnyAuthorities: [AuthorityType.VIEW_VOICE_TRADES],
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/HouseCashListView.vue'),
        },
      ],
    },
    {
      path: 'open-position',
      component: blankComponent('OpenPosition'),
      meta: {
        breadcrumbTitle: 'Open Positions',
        title: `Open Positions | ${PAGE_TITLE}`,
      },
      children: [
        {
          path: ':clientId?',
          props: true,
          meta: {
            ignoreBreadcrumb: true,
            title: `Open Positions | ${PAGE_TITLE}`,
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/OpenPositionListView.vue'),
        },
      ],
    },
    {
      path: 'liquidity-providers',
      component: blankComponent('LiquidityProviders'),
      meta: {
        breadcrumbTitle: 'Liquidity Providers',
        title: `Liquidity Providers | ${PAGE_TITLE}`,
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
            title: `Liquidity Providers | ${PAGE_TITLE}`,
            requireAnyAuthorities: [AuthorityType.VIEW_LIQUIDITY_PROVIDER_REPORTS],
          },
          component: () =>
            import(
              /* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/LiquidityProvidersListView.vue'
            ),
        },
        {
          path: 'liquidity-provider/:liquidityId?',
          meta: {
            ignoreBreadcrumb: true,
            title: `Liquidity Provider | ${PAGE_TITLE}`,
            requireAnyAuthorities: [AuthorityType.MANAGE_LIQUIDITY_PROVIDERS],
          },
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/LiquidityProviderCreatorView.vue'
            ),
        },
        {
          path: ':liquidityProviderId',
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/LiquidityProviderView.vue'),
          props: true,
          meta: {
            ignoreBreadcrumb: true,
            title: `Liquidity Provider | ${PAGE_TITLE}`,
            requireAnyAuthorities: [AuthorityType.VIEW_LIQUIDITY_PROVIDER_REPORTS],
          },
        },
      ],
    },
    {
      path: 'clients',
      component: blankComponent('ClientReports'),
      meta: {
        title: `Client Reports | ${PAGE_TITLE}`,
        ignoreBreadcrumb: true,
        requireAnyAuthorities: [AuthorityType.VIEW_VOICE_TRADES],
      },
      children: [
        {
          path: '',
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/ClientReportsView.vue'),
        },
      ],
    },
    {
      path: 'bank-accounts',
      component: blankComponent('BankAccounts'),
      meta: {
        breadcrumbTitle: 'Bank Accounts',
        title: `Bank Accounts | ${PAGE_TITLE}`,
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
            requireAnyAuthorities: [AuthorityType.VIEW_BANK_ACCOUNT_REPORTS],
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/BankAccountsListView.vue'),
        },
        {
          path: 'bank-account/:bankAccountId?',
          meta: {
            ignoreBreadcrumb: true,
            requireAnyAuthorities: [AuthorityType.MANAGE_BANK_ACCOUNTS],
          },
          props: true,
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/BankAccountCreatorView.vue'),
        },
        {
          path: ':bankAccountId',
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/BankAccountView.vue'),
          props: true,
          meta: {
            ignoreBreadcrumb: true,
            requireAnyAuthorities: [AuthorityType.VIEW_BANK_ACCOUNT_REPORTS],
          },
        },
      ],
    },
    {
      path: 'transactions',
      component: blankComponent('CashFlowTransactions'),
      meta: {
        breadcrumbTitle: 'Transactions Viewer',
        title: `Transactions | ${PAGE_TITLE}`,
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
            requireAllAuthorities: [AuthorityType.VIEW_CASH_TRANSACTION_REPORTS],
          },
          component: () =>
            import(
              /* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/CashTransactionsListView.vue'
            ),
        },
        {
          path: 'transaction/:transactionId?',
          meta: {
            ignoreBreadcrumb: true,
            requireAnyAuthorities: [AuthorityType.MANAGE_CASH_TRANSACTIONS],
          },
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/CashTransactionsCreatorView.vue'
            ),
        },
        {
          path: ':transactionId',
          meta: {
            ignoreBreadcrumb: true,
            requireAllAuthorities: [AuthorityType.VIEW_CASH_TRANSACTION_REPORTS],
          },
          props: true,
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/CashTransactionsView.vue'),
        },
      ],
    },
    {
      path: 'settlements',
      component: blankComponent('UpcomingSettlements'),
      meta: {
        title: `Settlements | ${PAGE_TITLE}`,
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
            requireAnyAuthorities: [AuthorityType.VIEW_VOICE_TRADES],
          },
          component: () =>
            import(
              /* webpackChunkName: "privateViews" */ '../views/dashboard/tradingDesk/VoiceTradeSettlementsView.vue'
            ),
        },
      ],
    },
    {
      path: 'bulk-import',
      component: blankComponent('BulkImport'),
      meta: {
        breadcrumbTitle: 'Bulk Import',
        title: 'Bulk Import - VTD | AH Admin',
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
          },
          component: () =>
            import(/* webpackChunkName: "bulkImportView" */ '../views/dashboard/tradingDesk/BulkImportView.vue'),
        },
      ],
    },
    {
      path: 'coutts-file-import',
      component: blankComponent('CouttsFileImport'),
      meta: {
        breadcrumbTitle: 'Coutts File Import',
        title: 'Coutts File Import - VTD | AH Admin',
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
            requireAnyAuthorities: [AuthorityType.MANAGE_COUTTS_BALANCES],
          },
          component: () =>
            import(
              /* webpackChunkName: "CouttsFileImportView" */ '../views/dashboard/tradingDesk/CouttsFileImportView.vue'
            ),
        },
      ],
    },
    {
      path: 'sucden-file-import',
      component: blankComponent('SucdenFileImport'),
      meta: {
        breadcrumbTitle: 'Sucden File Import',
        title: 'Sucden File Import- VTD | AH Admin',
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
          },
          component: () =>
            import(
              /* webpackChunkName: "SucdenFileImportView" */ '../views/dashboard/tradingDesk/SucdenFileImportView.vue'
            ),
        },
      ],
    },
    { path: '*', redirect: 'trades' },
  ],
};

const userManagementRoutes: AHAdminRouteConfig = {
  path: 'user-management',
  component: blankComponent('UserManagement'),
  meta: {
    auth: true,
    ignoreBreadcrumb: true,
  },
  children: [
    {
      path: 'users',
      component: blankComponent('Users'),
      meta: {
        title: `Users | ${PAGE_TITLE}`,
        ignoreBreadcrumb: true,
        requireAnyAuthorities: [AuthorityType.VIEW_USER_ACCOUNTS, AuthorityType.VIEW_USER_PERMISSIONS],
      },
      children: [
        {
          path: '',
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/userManagement/UsersListView.vue'),
          meta: {
            title: `Users List | ${PAGE_TITLE}`,
          },
        },
        {
          path: ':userId',
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/userManagement/UserDetailsView.vue'),
          props: true,
          meta: {
            title: `User Detail | ${PAGE_TITLE}`,
          },
        },
      ],
    },
    {
      path: 'roles-permissions',
      component: blankComponent('RolesPermissions'),
      meta: {
        title: `Roles & Permissions | ${PAGE_TITLE}`,
        ignoreBreadcrumb: true,
        requireAllAuthorities: [AuthorityType.VIEW_ROLE_AUTHORITIES],
      },
      children: [
        {
          path: ':ahRole?/:groupName?',
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/userManagement/RolesPermissionsView.vue'),
          meta: {
            title: `Roles & Permissions List | ${PAGE_TITLE}`,
          },
        },
      ],
    },
    { path: '*', redirect: 'users' },
  ],
};

const dashboard: AHAdminRouteConfig = {
  path: '/dashboard',
  component: () => import(/* webpackChunkName: "privateViews" */ '../views/dashboard/DashboardView.vue'),
  meta: { auth: true, ignoreBreadcrumb: true },
  children: [
    {
      path: '',
      component: () => import(/* webpackChunkName: "privateViews" */ '../views/dashboard/DashboardEmptyView.vue'),
    },
    {
      path: 'agents',
      component: blankComponent('Agents'),
      meta: {
        requireAllAuthorities: [
          AuthorityType.VIEW_MEMBERS,
          AuthorityType.VIEW_MEMBER_REPORTS,
          AuthorityType.VIEW_USER_ACCOUNTS,
        ],
        breadcrumbTitle: 'Agents',
        title: `Agents | ${PAGE_TITLE}`,
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/agents/AgentsListView.vue'),
        },
        dashboardAgentRoute(),
      ],
    },
    {
      path: 'partners',
      component: blankComponent('Partners'),
      meta: {
        breadcrumbTitle: 'Partners',
        title: `Partners | ${PAGE_TITLE}`,
        requireAnyAuthorities: [AuthorityType.VIEW_ALL_PARTNERS],
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/partners/PartnersListView.vue'),
        },
        dashboardPartnerRoute(),
      ],
    },
    {
      path: 'clients',
      component: blankComponent('Clients'),
      meta: {
        breadcrumbTitle: 'Clients',
        title: `Clients | ${PAGE_TITLE}`,
        requireAnyAuthorities: [AuthorityType.VIEW_CLIENTS],
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/clients/ClientsListView.vue'),
        },
        dashboardClientRoute(),
      ],
    },
    {
      path: 'individuals',
      component: blankComponent('Individuals'),
      meta: {
        breadcrumbTitle: 'Individuals',
        title: `Individuals | ${PAGE_TITLE}`,
        requireAllAuthorities: [
          AuthorityType.VIEW_MEMBERS,
          AuthorityType.VIEW_MEMBER_REPORTS,
          AuthorityType.VIEW_USER_ACCOUNTS,
        ],
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/individuals/IndividualsListView.vue'),
        },
        dashboardIndividualRoute(),
      ],
    },
    {
      path: 'trades',
      component: blankComponent('Trades'),
      meta: {
        breadcrumbTitle: 'Trades',
        title: `Trades | ${PAGE_TITLE}`,
        requireAnyAuthorities: [AuthorityType.VIEW_TRADE_REPORTS],
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/trades/TradesListView.vue'),
        },
        dashboardTradeRoute(),
      ],
    },
    {
      path: 'kill-switch',
      component: () => import(/* webpackChunkName: "privateViews" */ '../views/dashboard/trades/KillSwitchView.vue'),
      meta: {
        breadcrumbTitle: 'Kill Switch',
        title: `Kill Switch | ${PAGE_TITLE}`,
        requireAnyAuthorities: [AuthorityType.RESUME_SUSPEND_TRADING],
      },
    },

    {
      path: 'collateral-settings',
      component: () =>
        import(/* webpackChunkName: "privateViews" */ '../views/dashboard/trades/CollateralSettingsView.vue'),
      meta: {
        requireAnyAuthorities: [AuthorityType.VIEW_COLLATERAL_CONFIGURATIONS],
        breadcrumbTitle: 'Collateral Settings',
        title: `Collateral Settings | ${PAGE_TITLE}`,
      },
    },

    {
      path: 'trader-obo',
      component: blankComponent('TraderOBO'),
      meta: {
        requireAnyAuthorities: [AuthorityType.ACT_ON_BEHALF_OF],
        title: `Trading Service | ${PAGE_TITLE}`,
        breadcrumbTitle: 'Trading Service',
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/trades/obo/TraderOBOView.vue'),
        },
        dashboardTraderOBORoute(),
      ],
    },
    {
      path: 'dynamic-pnl',
      component: blankComponent('DynamicPnL'),
      meta: {
        requireAnyAuthorities: [AuthorityType.VIEW_PNL_REPORTS],
        ignoreBreadcrumb: true,
        title: 'Dynamic PNL Report',
      },
      children: [
        {
          path: '',
          props: true,
          meta: {
            ignoreBreadcrumb: true,
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/trades/DynamicPnLReportView.vue'),
        },
      ],
    },
    // TODO - DO: MCF screen will be hidden at the moment in favour of the new Credit and Limits screen, this will be completely removed at a later stage.
    // {
    //   path: 'margin-credit-facility',
    //   component: blankComponent('Margin'),
    //   meta: {
    //     breadcrumbTitle: 'Margin Credit Facility',
    //     title: `Margin Credit Facility | ${PAGE_TITLE}`,
    //     requireAnyAuthorities: [AuthorityType.VIEW_ALL_RISK_REPORTS],
    //   },
    //   children: [
    //     {
    //       path: '',
    //       meta: {
    //         ignoreBreadcrumb: true,
    //       },
    //       component: () =>
    //         import(
    //           /* webpackChunkName: "privateViews" */ '../views/dashboard/creditControl/MarginCreditFacilityView.vue'
    //         ),
    //     },
    //     dashboardClientMarginFacilityRoute(),
    //   ],
    // },
    {
      path: 'credit-and-limits',
      component: blankComponent('CreditAndLimits'),
      meta: {
        breadcrumbTitle: 'Credit & Limits',
        title: `Credit & Limits | ${PAGE_TITLE}`,
        requireAnyAuthorities: [AuthorityType.VIEW_ALL_RISK_REPORTS],
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/creditControl/CreditAndLimitsView.vue'),
        },
        dashboardClientCreditAndLimitsRoute(),
      ],
    },
    {
      path: 'risk-monitors',
      component: blankComponent(),
      meta: {
        ignoreBreadcrumb: true,
        title: `Risk Monitors | ${PAGE_TITLE}`,
        requireAnyAuthorities: [AuthorityType.VIEW_ALL_RISK_REPORTS],
      },
      children: [
        {
          path: '',
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/risk/RiskMonitorsView.vue'),
        },
      ],
    },
    {
      path: 'aml-monitor',
      component: blankComponent(),
      meta: {
        ignoreBreadcrumb: true,
        title: `AML Monitoring | ${PAGE_TITLE}`,
        requireAnyAuthorities: [AuthorityType.VIEW_ALL_RISK_REPORTS],
      },
      children: [
        {
          path: '',
          component: () => import(/* webpackChunkName: "privateViews" */ '../views/dashboard/risk/AMLMonitorView.vue'),
        },
      ],
    },
    {
      path: 'delta-risk',
      component: blankComponent(),
      meta: {
        ignoreBreadcrumb: true,
        title: `Client Delta Risk | ${PAGE_TITLE}`,
        requireAnyAuthorities: [AuthorityType.VIEW_DELTA_RISK_REPORTS],
      },
      children: [
        {
          path: '',
          component: () => import(/* webpackChunkName: "privateViews" */ '../views/dashboard/risk/DeltaRiskView.vue'),
        },
      ],
    },
    {
      path: 'eod-market-data',
      component: blankComponent(),
      meta: {
        ignoreBreadcrumb: true,
        title: `EOD Market Data | ${PAGE_TITLE}`,
        requireAnyAuthorities: [AuthorityType.VIEW_EOD_SPOT_RATES_REPORTS],
      },
      children: [
        {
          path: '',
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/creditControl/EODMarketDataView.vue'),
          props: true,
        },
      ],
    },
    {
      path: 'payment-fees',
      component: () =>
        import(/* webpackChunkName: "privateViews" */ '../views/dashboard/feeManagement/PaymentFeesView.vue'),
      meta: {
        requireAnyAuthorities: [AuthorityType.VIEW_FEES],
        breadcrumbTitle: 'Payment Fees',
        title: `Payment Fees | ${PAGE_TITLE}`,
      },
    },
    {
      path: 'funding-fees',
      component: () =>
        import(/* webpackChunkName: "privateViews" */ '../views/dashboard/feeManagement/FundingFeesView.vue'),
      meta: {
        requireAnyAuthorities: [AuthorityType.VIEW_FEES],
        breadcrumbTitle: 'Funding Fees',
        title: `Funding Fees | ${PAGE_TITLE}`,
      },
    },
    {
      path: 'settings',
      component: () =>
        import(/* webpackChunkName: "privateViews" */ '../views/dashboard/settings/AccountSettingsView.vue'),
      meta: {
        title: `Account Settings | ${PAGE_TITLE}`,
      },
    },
    ...(config.showDevTools
      ? [
          {
            path: 'dev-settings',
            component: () =>
              import(/* webpackChunkName: "privateViews" */ '../views/dashboard/settings/DevSettingsView.vue'),
            meta: {
              title: `Dev Settings | ${PAGE_TITLE}`,
            },
          },
        ]
      : []),
    {
      path: 'cobbalances',
      component: () => import(/* webpackChunkName: "privateViews" */ '../views/dashboard/finance/CobBalancesView.vue'),
      meta: {
        requireAnyAuthorities: [AuthorityType.VIEW_COB_REPORTS],
        breadcrumbTitle: 'COB Balances',
        title: `COB Balances | ${PAGE_TITLE}`,
      },
    },
    {
      path: '',
      component: blankComponent('BackOffice'),
      meta: {
        ignoreBreadcrumb: true,
      },
      children: [
        {
          path: 'beneficiary-fields',
          meta: {
            ignoreBreadcrumb: true,
            title: 'Beneficiary Fields',
            requireAnyAuthorities: [AuthorityType.UPDATE_MANDATORY_BENEFICIARY_FIELDS],
          },
          component: () =>
            import(
              /* webpackChunkName: "privateViews" */ '../views/dashboard/beneficiaries/BeneficiariesFieldsView.vue'
            ),
        },
        {
          path: 'upcoming-settlements',
          meta: {
            ignoreBreadcrumb: true,
            title: 'Upcoming Settlements',
            requireAnyAuthorities: [AuthorityType.VIEW_SETTLEMENT_REPORTS],
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/trades/SettlementsListView.vue'),
        },
        {
          path: 'payments',
          meta: {
            ignoreBreadcrumb: true,
            title: 'Payments',
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/payments/PaymentsListView.vue'),
        },
        {
          path: 'outstanding-fees',
          meta: {
            breadcrumbTitle: 'Outstanding Fees',
            title: 'Outstanding Fees',
            requireAnyAuthorities: [AuthorityType.VIEW_OUTSTANDING_FEE_REPORTS],
          },
          component: blankComponent(),
          children: [
            {
              path: '',
              meta: {
                ignoreBreadcrumb: true,
                title: 'Outstanding Fees',
              },
              component: () =>
                import(
                  /* webpackChunkName: "privateViews" */ '../views/dashboard/feeManagement/OutstandingFeesView.vue'
                ),
            },
            {
              path: ':userType/:clientOrPartnerId',
              component: () =>
                import(/* webpackChunkName: "privateViews" */ '../views/dashboard/trades/CustomFeesView.vue'),
              props: true,
              meta: {
                breadcrumbTitle: (route: AHAdminRoute) => {
                  const services = getServices();
                  const userType = (route.params as any).userType;
                  if (userType && userType === 'client' && (route.params as any).clientOrPartnerId) {
                    return services.client
                      .getClient((route.params as any).clientOrPartnerId)
                      .toPromise()
                      .then(
                        (client) => `${client.name}`,
                        () => 'Not Found!'
                      );
                  } else if (userType && userType === 'partner' && (route.params as any).clientOrPartnerId) {
                    if ((route.params as any).clientOrPartnerId === SYSTEM_ID) {
                      // SYSTEM_ID represents the House Account Partner
                      return 'House account';
                    }
                    return services.partner
                      .getPartner((route.params as any).clientOrPartnerId)
                      .toPromise()
                      .then(
                        (partner) => `${partner.name}`,
                        () => 'Not Found!'
                      );
                  }
                  return 'No ID';
                },
              },
            },
          ],
        },
      ],
    },
    {
      path: 'settled-commissions',
      component: () =>
        import(/* webpackChunkName: "privateViews" */ '../views/dashboard/finance/SettledCommissionsView.vue'),
      meta: {
        requireAllAuthorities: [AuthorityType.VIEW_SETTLED_COMMISSIONS_REPORTS, AuthorityType.VIEW_ALL_PARTNERS],
        breadcrumbTitle: 'Settled Commission Report',
        title: `Settled Commission Report | ${PAGE_TITLE}`,
      },
    },
    {
      path: 'liquidity-management',
      component: blankComponent('LiquidityManagement'),
      meta: {
        breadcrumbTitle: 'Liquidity Management',
        title: 'Liquidity Management',
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
            requireAnyAuthorities: [AuthorityType.VIEW_WALLETS, AuthorityType.VIEW_FUNDING_DETAILS],
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/wallets/LiquidityManagementView.vue'),
        },
        {
          path: ':walletId',
          props: true,
          meta: {
            ignoreBreadcrumb: true,
            requireAnyAuthorities: [AuthorityType.VIEW_TRANSACTIONS],
          },
          component: () =>
            import(
              /* webpackChunkName: "privateViews" */ '../views/dashboard/wallets/LiquidityManagementDetailsView.vue'
            ),
        },
      ],
    },
    {
      path: 'virtual-wallets',
      component: blankComponent('VirtualWallets'),
      meta: {
        breadcrumbTitle: 'Virtual Wallets',
        title: 'Virtual Wallets',
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
            requireAnyAuthorities: [AuthorityType.VIEW_WALLETS],
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/wallets/VirtualWalletsView.vue'),
        },
        {
          path: ':walletId',
          props: true,
          meta: {
            ignoreBreadcrumb: true,
            requireAnyAuthorities: [AuthorityType.VIEW_TRANSACTIONS],
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/wallets/VirtualWalletDetailsView.vue'),
        },
      ],
    },
    {
      path: 'manual-funds-match',
      component: blankComponent('ManualFundsMatch'),
      meta: {
        breadcrumbTitle: 'Manual Funds Match',
        title: 'Manual Funds Match',
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
            requireAnyAuthorities: [AuthorityType.VIEW_RECONCILABLE_TRANSACTIONS],
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/wallets/ManualFundsMatchView.vue'),
        },
      ],
    },
    {
      path: 'updates',
      component: blankComponent('Updates'),
      meta: {
        breadcrumbTitle: 'Updates',
        title: `Updates | ${PAGE_TITLE}`,
      },
      children: [
        {
          path: '',
          meta: {
            ignoreBreadcrumb: true,
            requireAnyAuthorities: [AuthorityType.VIEW_SOFTWARE_UPDATES],
          },
          component: () => import(/* webpackChunkName: "privateViews" */ '../views/dashboard/updates/UpdatesView.vue'),
        },
        {
          path: 'add',
          meta: {
            ignoreBreadcrumb: true,
            requireAnyAuthorities: [AuthorityType.MANAGE_SOFTWARE_UPDATES],
          },
          component: () =>
            import(/* webpackChunkName: "privateViews" */ '../views/dashboard/updates/AddUpdatesView.vue'),
        },
      ],
    },
    complianceRoutes,
    reconciliationRoutes,
    voiceTradesRoutes,
    userManagementRoutes,
    { path: '*', redirect: 'agents' },
  ],
};

function dashboardTraderOBORoute(pathPrefix = ''): AHAdminRouteConfig {
  return {
    path: `${pathPrefix ? pathPrefix + '/' : ''}:clientId`,
    props: true,
    component: blankComponent('TraderServiceOBO'),
    meta: {
      title: `Trading Service | ${PAGE_TITLE}`,
      requireAnyAuthorities: [AuthorityType.ACT_ON_BEHALF_OF],
      ignoreBreadcrumb: true,
    },
    children: [
      {
        path: '',
        component: () =>
          import(/* webpackChunkName: "privateViews" */ '../views/dashboard/trades/obo/TradeServiceOBOView.vue'),
        props: true,
        meta: {
          ignoreBreadcrumb: true,
        },
        children: [
          {
            path: '',
            component: () =>
              import(
                /* webpackChunkName: "privateViews" */ '../views/dashboard/trades/obo/sub_views/OBODashboardView.vue'
              ),
            props: true,
            meta: {
              ignoreBreadcrumb: true,
            },
          },
          {
            path: 'new-trade',
            component: () =>
              import(
                /* webpackChunkName: "privateViews" */ '../views/dashboard/trades/obo/sub_views/TradeCreatorView.vue'
              ),
            props: true,
            meta: {
              ignoreBreadcrumb: true,
            },
          },
          {
            path: 'manage-trades',
            component: blankComponent('ManageTrades'),
            props: true,
            meta: {
              ignoreBreadcrumb: true,
            },
            children: [
              {
                path: '',
                component: () =>
                  import(
                    /* webpackChunkName: "privateViews" */ '../views/dashboard/trades/obo/sub_views/TradesManagerView.vue'
                  ),
                props: false,
                meta: {
                  ignoreBreadcrumb: true,
                },
              },
              {
                path: ':tradeId',
                component: () =>
                  import(
                    /* webpackChunkName: "privateViews" */ '../views/dashboard/trades/obo/sub_views/TradeAmendView.vue'
                  ),
                props: true,
                meta: {
                  ignoreBreadcrumb: true,
                },
              },
            ],
          },
          {
            path: 'customfeegrid',
            component: () =>
              import(
                /* webpackChunkName: "privateViews" */ '../views/dashboard/trades/obo/sub_views/ClientCustomFeesView.vue'
              ),
            props: true,
            meta: {
              ignoreBreadcrumb: true,
              header: 'you are managing',
            },
          },
          {
            path: 'wallets/:walletId?',
            component: () =>
              import(/* webpackChunkName: "privateViews" */ '../views/dashboard/trades/obo/sub_views/WalletsView.vue'),
            props: true,
            meta: {
              ignoreBreadcrumb: true,
            },
          },
          {
            path: 'limits-collateral',
            component: () =>
              import(
                /* webpackChunkName: "privateViews" */ '../views/dashboard/trades/obo/sub_views/LimitsAndCollateralManagementView.vue'
              ),
            meta: {
              ignoreBreadcrumb: true,
            },
          },
          {
            path: 'credit-mtm',
            component: () =>
              import(
                /* webpackChunkName: "privateViews" */ '../views/dashboard/trades/obo/sub_views/CreditAndMarkToMarketManagementView.vue'
              ),
            meta: {
              ignoreBreadcrumb: true,
            },
          },
          {
            path: 'beneficiaries/:beneficiaryId?',
            component: () =>
              import(
                /* webpackChunkName: "privateViews" */ '../views/dashboard/trades/obo/sub_views/BeneficiariesView.vue'
              ),
            props: true,
            meta: {
              ignoreBreadcrumb: true,
            },
          },
          {
            path: 'payments/:beneficiaryId?',
            component: () =>
              import(
                /* webpackChunkName: "privateViews" */ '../views/dashboard/trades/obo/sub_views/BeneficiariesView.vue'
              ),
            props: true,
            meta: {
              ignoreBreadcrumb: true,
            },
          },
        ],
      },
    ],
  };
}

export const routes: AHAdminRouteConfig[] = [
  dashboard,
  {
    path: '/email-change/:token',
    component: ConfirmEmailChangeView,
    props: true,
    meta: { auth: true },
  },
  {
    path: '/',
    component: blankComponent('PublicRoutes'),
    children: [
      ...sessionRoutes,
      ...errorRoutes,
      /**
       * Catch-all route:
       * - Will redirect to /login if no active session exists
       * - Will redirect to best available route (defaulting to '/dashboard')
       */
      {
        path: '*',
        beforeEnter(to, from, next) {
          storeSetupDone.then(() => {
            const authModule = useAuthStore();
            if (!authModule.hasValidSession) {
              next({ path: '/login' });
            } else {
              next(getFirstAvailableLink(authModule) || '/dashboard');
            }
          });
        },
      },
    ],
  },
];
